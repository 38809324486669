import React, { useEffect, useState } from 'react';
import './Restaurant.css';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Upload,
  Select,
  Breadcrumb,
  Radio,
  Tabs,
  Card,
  Checkbox,
  Image,
} from 'antd';
import { getState, getAllState, getStateById } from '../../Actions/StateAction';
import { getTimeZone } from '../../Actions/TimezoneAction';
import {
  getRestaurant,
  createRestaurantAction,
  updateRestaurantAction,
  getRestaurantById,
  getRestaurantDetails,
} from '../../Actions/RestaurantAction';
import {
  getCountry,
  getCountryById,
  getAllCountry,
} from '../../Actions/countryAction';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { falseyValueCases, ImageEnv } from '../CommonLogics/CommonMethods';
import { createrestaurantSchema, editSchema } from '../../Utils/Schema';
import PhoneInput from 'react-phone-input-2';
import logo from '../../assests/logo.svg';
import Location from './Location';
import OprationTiming from './OprationTiming';
import FSSAIDetails from './FSSAIDetails';
import { LuAsterisk } from 'react-icons/lu';
import { getUsers } from '../../Actions/authenticationAction';
import { useNavigate } from 'react-router-dom';
import { deleteImage } from '../../Actions/ImageAction';
import { MdDeleteOutline } from 'react-icons/md';

const RestarantModalForm = (props) => {
  const {
    editId,
    setEditId,
    setIsModalVisible,
    setIsDefaultImageVisible,
    showUploadImage,
    setShowUploadImage,
    isDefaultImageVisible,
    currentPage,
    setCurrentPage,
    setTabkey,
    isType,
    setIsType,
  } = props;
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const [newFiles, setNewFiles] = useState();
  const IsEmpty = _.isEmpty(newFiles) ? true : false;
  const [preloadedRestaurantValue, setPreloadedRestaurantValue] = useState('');

  const [countryCode, setCountryCode] = useState('');
  const [number, setNumber] = useState('');
  const [displayNumber, setDisplayNumber] = useState('');
  const { Option } = Select;
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();

  // const phoneNumberMethod = (value, country) => {
  //   setCountryCode(country.dialCode);
  //   setDisplayNumber(value);
  //   setNumber(value.slice(country.dialCode.length));
  // };

  const handleChange = (e) => {
    e.target.value = setNumber(e.target.value.replace(/\D/g, ''));
  };

  useEffect(() => {
    if (!editId) {
      dispatch(getAllCountry(`api/countries`));
    }
  }, []);
  const {
    allRestaurant,
    isCreateRestaurantLoading,
    isUpdateRestaurantLoading,
    isRestaurantloading,
    allCountries,
    allState,
    allTimeZone,
    restaurant,
    setCountryId,
    country,
    restaurantdeatils,
    State,
    TimeZone,
    allStates,
    setRestaurantId,
  } = useSelector((state) => {
    const {
      allRestaurant,
      isCreateRestaurantLoading,
      isUpdateRestaurantLoading,
      isRestaurantloading,
      restaurant,
      restaurantdeatils,
      setRestaurantId,
    } = state.restaurant;
    const { allCountries, setCountryId, country } = state.country;
    const { allState, State, allStates } = state.states;
    const { setCode, allTimeZone, TimeZone } = state.timeZones;
    return {
      State,
      restaurant,
      allTimeZone,
      setCode,
      allState,
      setCountryId,
      allCountries,
      allRestaurant,
      isCreateRestaurantLoading,
      isUpdateRestaurantLoading,
      isRestaurantloading,
      country,
      restaurantdeatils,
      TimeZone,
      allStates,
      setRestaurantId,
    };
  });
  const [countryId, setcountryId] = useState(country?.name);
  const [stateId, setstateId] = useState(State?.name);
  const [timeZoneId, settimeZoneId] = useState(allTimeZone);

  const { organizations } = allRestaurant;

  const the_restaurant = _.filter(organizations, function (o) {
    return o.id === editId;
  });

  if (editId) {
    the_restaurant[0].country_id = country?.name;
  }
  if (editId) {
    the_restaurant[0].state_id = State?.name;
  }
  if (editId) {
    the_restaurant[0].time_zone = allTimeZone;
  }

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: editId && the_restaurant[0].name,
      legal_entity_name: editId && the_restaurant[0].legal_entity_name,
    },
    resolver: yupResolver(editId ? editSchema : createrestaurantSchema),
  });

  // useEffect(() => {
  //   if (editId) {
  //     setPreloadedRestaurantValue(restaurant);
  //     reset(restaurant);
  //   }
  // }, [restaurant, reset]);

  const [checkedValue, setCheckedValue] = useState(
    editId && the_restaurant[0]?.is_cafe ? true : false
  );
  const [checkedValueCashier, setCheckedValueCashier] = useState(
    editId && the_restaurant[0]?.is_cashier ? true : false
  );
  const [checkedValueOnlineStore, setCheckedValueOnlineStore] = useState(
    editId && the_restaurant[0]?.online_store ? true : false
  );
  const [checkedValueDineIn, setCheckedValueDineIn] = useState(
    editId && the_restaurant[0].is_dine_in ? true : false
  );
  const [checkedValueTakeaway, setCheckedValueTakeaway] = useState(
    editId && the_restaurant[0]?.is_take_away ? true : false
  );
  const [checkedValueIsDelivery, setCheckedValueIsDelivery] = useState(
    editId && the_restaurant[0]?.is_delivery ? true : false
  );
  const [checkedValueIsqsr, setCheckedValueIsqsr] = useState(
    editId && the_restaurant[0]?.is_qsr ? true : false
  );
  const [checkedValueFoodCourt, setCheckedValueFoodCourt] = useState(
    editId && the_restaurant[0]?.is_food_court ? true : false
  );
  const [checkedValueMobileRestaurant, setCheckedValueMobileRestaurant] =
    useState(editId && the_restaurant[0]?.is_mobile_restaurant ? true : false);

  const [checkedValueCloudKitchen, setCheckedValueCloudKitchen] = useState(
    editId && the_restaurant[0]?.is_cloud_kitchen ? true : false
  );
  const [checkedValueIsDashboard, setCheckedValueIsDashboard] = useState(
    editId && the_restaurant[0]?.is_dashboard ? true : false
  );
  const [checkedValueInventory, setCheckedValueInventory] = useState(
    editId && the_restaurant[0]?.is_inventory ? true : false
  );
  const [checkedValueOrder, setCheckedValueOrder] = useState(
    editId && the_restaurant[0]?.is_orders ? true : false
  );
  const [checkedValueWorkstation, setCheckedValueWorkstation] = useState(
    editId && the_restaurant[0]?.is_workstation ? true : false
  );
  const [checkedValueSetting, setCheckedValueSetting] = useState(
    editId && the_restaurant[0]?.is_settings ? true : false
  );
  const [checkedValueTest, setCheckedValueTest] = useState(
    editId && the_restaurant[0]?.is_test ? true : false
  );
  const onChange = (e) => {
    setCheckedValue(e);
  };
  const onChangeCashier = (e) => {
    setCheckedValueCashier(e);
  };
  const onChangeOnlineStore = (e) => {
    setCheckedValueOnlineStore(e);
  };

  const onChangeDineIn = (e) => {
    setCheckedValueDineIn(e);
  };
  const onChangeTakeaway = (e) => {
    setCheckedValueTakeaway(e);
  };
  const onChangeIsDelivery = (e) => {
    setCheckedValueIsDelivery(e);
  };
  const onChangeIsqsr = (e) => {
    setCheckedValueIsqsr(e);
  };
  const onChangeFoodCourt = (e) => {
    setCheckedValueFoodCourt(e);
  };
  const onChangeMobileRestaurant = (e) => {
    setCheckedValueMobileRestaurant(e);
  };
  const onChangeCloudKitchen = (e) => {
    setCheckedValueCloudKitchen(e);
  };
  const onChangeIsDashboard = (e) => {
    setCheckedValueIsDashboard(e);
  };
  const onChangeInventory = (e) => {
    setCheckedValueInventory(e);
  };
  const onChangeOrder = (e) => {
    setCheckedValueOrder(e);
  };
  const onChangeWorkstation = (e) => {
    setCheckedValueWorkstation(e);
  };
  const onChangeSetting = (e) => {
    setCheckedValueSetting(e);
  };
  const onChangeTest = (e) => {
    setCheckedValueTest(e);
  };
  const handleClose = () => {
    setIsModalVisible('');
    setEditId('');
    setValue('name', '');
    setValue('legal_entity_name', '');
    setValue('published', '');
    setValue('org_logo', '');
    setValue('country_id', '');
    setValue('state_id', '');
    setValue('time_zone', '');
    setValue('email', '');
    setValue('country_code', '');
    // setValue('phone', '');
    setValue('is_cafe', '');
    setNewFiles([]);
    setFileList([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    clearErrors();
  };
  const handleCancel = () => {
    reset(preloadedRestaurantValue);
    setEditId('');
    // reset();
    setValue('name', '');
    setValue('legal_entity_name', '');
    setValue('published', '');
    setValue('org_logo', '');
    setValue('country_id', '');
    setValue('state_id', '');
    setValue('time_zone', '');
    setValue('email', '');
    setValue('country_code', '');
    setNumber('');
    // setNumber('')
    setValue('phone', '');
    setValue('is_cafe', '');
    setNewFiles([]);
    setFileList([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    clearErrors();
  };
  const handleeditCancel = () => {
    reset(preloadedRestaurantValue);
    setNewFiles([]);
    setFileList([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    clearErrors();
  };
  const handleDeleteImage = (key) => {
    dispatch(deleteImage(key, successCallbackImageDelete));
  };
  const successCallbackImageDelete = () => {
    setCurrentPage(1);
    setIsType('');
    setIsModalVisible(false);
    dispatch(getRestaurant(`api/organizations?page=${currentPage}`));
  };
  const selectCountry = (e) => {
    const countryObj = _.filter(allCountries, function (o) {
      return o.id === e;
    });
    setcountryId(countryObj[0]?.name);
    dispatch(getCountryById(countryObj[0]?.id));
    dispatch(getAllState(`api/states?country_id=${countryObj[0]?.id}`));
    dispatch(getTimeZone(`api/time_zones?code=${countryObj[0]?.code}`));
  };
  const selectState = (e) => {
    const countryObj = _.filter(allStates, function (o) {
      return o.id === e;
    });
    setstateId(countryObj[0]?.name);
    dispatch(getStateById(countryObj[0]?.id));
  };
  const selectTimezone = (e) => {
    // const countryObj = _.filter(allTimeZone, function (o) {
    //   return o.id === e;
    // });
    // settimeZoneId(countryObj[0]);
    settimeZoneId(allTimeZone[0]);
    // dispatch(getStateById (countryObj[0]?.id));
  };
  useEffect(() => {
    if (editId) {
      dispatch(getRestaurantDetails());
    }
  }, [dispatch]);
  const handleCreateRestaurant = (data) => {
    if (!falseyValueCases(newFiles)) {
      data.org_logo = newFiles;
    }
    data.is_cafe = checkedValue;
    data.is_cashier = checkedValueCashier;
    data.online_store = checkedValueOnlineStore;
    data.is_dine_in = checkedValueDineIn;
    data.is_take_away = checkedValueTakeaway;
    data.is_delivery = checkedValueIsDelivery;
    data.is_qsr = checkedValueIsqsr;
    data.is_food_court = checkedValueFoodCourt;
    data.is_mobile_restaurant = checkedValueMobileRestaurant;
    data.is_cloud_kitchen = checkedValueCloudKitchen;
    data.is_dashboard = checkedValueIsDashboard;
    data.is_inventory = checkedValueInventory;
    data.is_orders = checkedValueOrder;
    data.is_workstation = checkedValueWorkstation;
    data.is_settings = checkedValueSetting;
    data.is_test = checkedValueTest;
    data.time_zone = timeZoneId;
    data.phone = number;
    dispatch(
      createRestaurantAction(
        data,
        successCreateRestaurant,
        failureCreateRestaurant,
        IsEmpty
      )
    );
  };

  const successCreateRestaurant = (data) => {
    setCurrentPage(1);
    setIsType('');
    setIsModalVisible(false);
    dispatch(getRestaurant(`api/organizations?page=${currentPage}`));
    localStorage.setItem('organization_id', data.id);
  };

  const failureCreateRestaurant = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse
          ? failureResponse?.error || failureResponse?.message
          : 'Something went wrong.'
      );
    }, 1000);
  };
  const handleEditRestaurantMethod = (data) => {
    data.id = editId;
    data.is_cafe = checkedValue;
    data.is_cashier = checkedValueCashier;
    data.online_store = checkedValueOnlineStore;
    data.is_dine_in = checkedValueDineIn;
    data.is_take_away = checkedValueTakeaway;
    data.is_delivery = checkedValueIsDelivery;
    data.is_qsr = checkedValueIsqsr;
    data.is_food_court = checkedValueFoodCourt;
    data.is_mobile_restaurant = checkedValueMobileRestaurant;
    data.is_cloud_kitchen = checkedValueCloudKitchen;
    data.is_dashboard = checkedValueIsDashboard;
    data.is_inventory = checkedValueInventory;
    data.is_orders = checkedValueOrder;
    data.is_workstation = checkedValueWorkstation;
    data.is_settings = checkedValueSetting;
    data.is_test = checkedValueTest;
    if (!falseyValueCases(newFiles)) {
      data.org_logo = newFiles;
    }
    dispatch(
      updateRestaurantAction(data, UpdateCallBack, faliureUpdate, IsEmpty)
    );
  };

  const UpdateCallBack = (data) => {
    dispatch(getRestaurant(`api/organizations?page=${currentPage}`));
    dispatch(getRestaurantDetails());
    setTimeout(() => {
      message.success('Your Restaurant Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  const propsForUpload = {
    action: handleCreateRestaurant || handleEditRestaurantMethod,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };

  const handleUploadMethod = (e) => {
    setShowUploadImage(true);
    setIsDefaultImageVisible(false);
    setFileList(e.fileList.slice(-1));
  };
  const inputStyles = {
    WebkitBoxShadow: '0 0 0 30px #f6f6f6 inset',
  };
  const isLoading = isCreateRestaurantLoading || isUpdateRestaurantLoading;
  return (
    <Spin spinning={isLoading}>
      <Card
        style={{
          backgroundColor: '#f6f6f6',
          border: 'rgb(229, 229, 229,1)',
        }}
        // scrollable={{ x: 1000, y:500 }}
      >
        <form
          onSubmit={handleSubmit(
            editId ? handleEditRestaurantMethod : handleCreateRestaurant
          )}
          // style={{ height: '100%' }}
        >
          <Spin spinning={isRestaurantloading}>
            <>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Organization Logo
                  </Typography>
                  <Col></Col>
                  <Row>
                    <Col span={24}>
                      <Row>
                        <Col span={8}>
                          <Upload
                            listType='picture-card'
                            {...propsForUpload}
                            maxCount={1}
                            className='formHeading'
                            accept='image/png,image/jpeg'
                          >
                            <Row>
                              <Col span={24}>
                                <img src={logo}></img>
                              </Col>
                            </Row>
                          </Upload>
                        </Col>
                        <Col style={{ marginLeft: '-110px' }}>
                          {newFiles
                            ? ''
                            : editId &&
                              the_restaurant[0]?.logo_url && (
                                <Col>
                                  <Image
                                    src={the_restaurant[0]?.logo_url}
                                    width='100px'
                                    height='103px'
                                  />
                                  <MdDeleteOutline
                                    onClick={() =>
                                      handleDeleteImage(
                                        the_restaurant[0]?.org_logo
                                      )
                                    }
                                    style={{
                                      width: '20px',
                                      height: '20px',
                                      position: 'absolute',
                                      top: '83%',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Col>
                              )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Organization Name <LuAsterisk className='asterisk' />
                  </Typography>
                  <Controller
                    as={<Input style={inputStyles} />}
                    name='name'
                    control={control}
                    className='inputLabel'
                  />
                  {errors.name && (
                    <p style={{ color: 'red' }}>{errors.name.message}</p>
                  )}
                </Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Legal Entity Name <LuAsterisk className='asterisk' />
                  </Typography>
                  <Controller
                    as={<Input style={inputStyles} />}
                    name='legal_entity_name'
                    control={control}
                    className='inputLabel'
                  />
                  {errors.legal_entity_name && (
                    <p style={{ color: 'red' }}>
                      {errors.legal_entity_name.message}
                    </p>
                  )}
                </Col>
              </Row>
              {editId && isType === 'updateForm' ? (
                ''
              ) : (
                <>
                  <Row gutter={[16, 16]} className='button'>
                    <Col
                      xl={16}
                      lg={16}
                      md={16}
                      sm={24}
                      xs={24}
                      className='formRows'
                    >
                      <Typography className='formHeading'>
                        Mobile Number
                      </Typography>
                      <Controller
                        render={() => (
                          <Input
                            className='inputLabel'
                            maxLength={10}
                            onChange={handleChange}
                            value={number}
                          />
                        )}
                        name='phone'
                        control={control}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} className='button'>
                    <Col
                      xl={16}
                      lg={16}
                      md={16}
                      sm={24}
                      xs={24}
                      className='formRows'
                    >
                      <Typography className='formHeading'>Email</Typography>
                      <Controller
                        as={<Input style={inputStyles} />}
                        name='email'
                        control={control}
                        defaultValue={editId ? restaurantdeatils?.email : ''}
                        className='inputLabel'
                      />
                      {errors.email && (
                        <p style={{ color: 'red' }}>{errors.email.message}</p>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} className='button'>
                    <Col
                      xl={8}
                      lg={8}
                      md={8}
                      sm={24}
                      xs={24}
                      className='formRows'
                    >
                      <Typography className='formHeading'>
                        Country <LuAsterisk className='asterisk' />
                      </Typography>
                      <Controller
                        name='country_id'
                        as={
                          <Select
                            placeholder='select country'
                            style={{ width: '100%', borderRadius: '4px' }}
                            showSearch={true}
                            className='selection'
                            options={allCountries?.map((val, i) => {
                              return {
                                label: val.name,
                                value: val.id,
                              };
                            })}
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={selectCountry}
                          ></Select>
                        }
                        defaultValue={editId ? restaurantdeatils?.country : ''}
                        control={control}
                      />
                      {errors.country_id && (
                        <p style={{ color: 'red' }}>
                          {errors.country_id.message}
                        </p>
                      )}
                    </Col>
                    <Col
                      xl={8}
                      lg={8}
                      md={8}
                      sm={24}
                      xs={24}
                      className='formRows'
                    >
                      <Typography className='formHeading'>
                        State <LuAsterisk className='asterisk' />
                      </Typography>
                      <Controller
                        name='state_id'
                        as={
                          <Select
                            placeholder='Select State'
                            style={{ width: '100%', borderRadius: '4px' }}
                            className='selection'
                            options={allStates?.map((val, i) => {
                              return {
                                label: val.name,
                                value: val.id,
                              };
                            })}
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={selectState}
                          >
                            {' '}
                          </Select>
                        }
                        defaultValue={editId ? restaurantdeatils?.state : ''}
                        control={control}
                      />
                      {errors.state_id && (
                        <p style={{ color: 'red' }}>
                          {errors.state_id.message}
                        </p>
                      )}
                    </Col>
                    <Col
                      xl={8}
                      lg={8}
                      md={8}
                      sm={24}
                      xs={24}
                      className='formRows'
                    ></Col>
                  </Row>
                  <Row gutter={[16, 16]} className='button'>
                    <Col
                      xl={16}
                      lg={16}
                      md={16}
                      sm={24}
                      xs={24}
                      className='formRows'
                    >
                      <Typography className='formHeading'>
                        Time Zone <LuAsterisk className='asterisk' />
                      </Typography>
                      <Controller
                        name='time_zone'
                        as={
                          <Select
                            placeholder='Select Time Zone'
                            style={{ width: '100%', borderRadius: '4px' }}
                            className='selection'
                            onSelect={selectTimezone}
                          >
                            {allTimeZone?.map((val, i) => {
                              return (
                                <Option value={val.code} key={i}>
                                  {allTimeZone}
                                </Option>
                              );
                            })}
                          </Select>
                        }
                        defaultValue={
                          editId
                            ? restaurantdeatils?.organization_time_zone
                            : ''
                        }
                        control={control}
                      />
                      {errors.time_zone && (
                        <p style={{ color: 'red' }}>
                          {errors.time_zone.message}
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} className='button'>
                    <Col
                      xl={16}
                      lg={16}
                      md={16}
                      sm={24}
                      xs={24}
                      className='formRows'
                    >
                      <Typography className='formHeading'>
                        Fax Number
                      </Typography>
                      <Controller
                        style={{ border: '10px solid yellow' }}
                        as={<Input style={inputStyles} />}
                        name='fax'
                        control={control}
                        className='inputLabel'
                        defaultValue={editId ? restaurantdeatils?.fax : ''}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Organization Capabilities
                  </Typography>
                  <Card className='card'>
                    <Row gutter={[18, 18]} justify='space-around'>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_dine_in'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueDineIn}
                                  onChange={(e) =>
                                    onChangeDineIn(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Dine In
                            </Typography>
                          </Col>
                        </Row>
                      </Col>

                      <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_take_away'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueTakeaway}
                                  onChange={(e) =>
                                    onChangeTakeaway(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Takeaway
                            </Typography>
                          </Col>
                        </Row>
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_delivery'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueIsDelivery}
                                  onChange={(e) =>
                                    onChangeIsDelivery(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Delivery
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>Store Type</Typography>
                  <Card className='card'>
                    <Row gutter={[18, 18]} justify='space-around'>
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_cafe'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValue}
                                  onChange={(e) => onChange(e.target.checked)}
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Is Cafe
                            </Typography>
                          </Col>
                        </Row>
                      </Col>

                      <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_cashier'
                              control={control}
                              defaultValue={
                                editId ? the_restaurant[0]?.is_cashier : false
                              }
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueCashier}
                                  onChange={(e) =>
                                    onChangeCashier(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Is Cashier
                            </Typography>
                          </Col>
                        </Row>
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='online_store'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueOnlineStore}
                                  onChange={(e) =>
                                    onChangeOnlineStore(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Online Store
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      gutter={[18, 18]}
                      className='button'
                      justify='space-around'
                    >
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_food_court'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueFoodCourt}
                                  onChange={(e) =>
                                    onChangeFoodCourt(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Is Food Court
                            </Typography>
                          </Col>
                        </Row>
                      </Col>

                      <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_mobile_restaurant'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueMobileRestaurant}
                                  onChange={(e) =>
                                    onChangeMobileRestaurant(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Is Mobile Restaurant
                            </Typography>
                          </Col>
                        </Row>
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_cloud_kitchen'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueCloudKitchen}
                                  onChange={(e) =>
                                    onChangeCloudKitchen(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Is Cloud Kitchen
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      gutter={[18, 18]}
                      className='button'
                      justify='space-around'
                    >
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_dashboard'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueIsDashboard}
                                  onChange={(e) =>
                                    onChangeIsDashboard(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Is Dashboard
                            </Typography>
                          </Col>
                        </Row>
                      </Col>

                      <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_inventory'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueInventory}
                                  onChange={(e) =>
                                    onChangeInventory(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Is Inventory
                            </Typography>
                          </Col>
                        </Row>
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_order'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueOrder}
                                  onChange={(e) =>
                                    onChangeOrder(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Is Order
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row
                      gutter={[18, 18]}
                      className='button'
                      justify='space-around'
                    >
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_workstation'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueWorkstation}
                                  onChange={(e) =>
                                    onChangeWorkstation(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Is Workstation
                            </Typography>
                          </Col>
                        </Row>
                      </Col>

                      <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_settings'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueSetting}
                                  onChange={(e) =>
                                    onChangeSetting(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Is Settings
                            </Typography>
                          </Col>
                        </Row>
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_test'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueTest}
                                  onChange={(e) =>
                                    onChangeTest(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Is Test
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      gutter={[18, 18]}
                      className='button'
                      justify='space-around'
                    >
                      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Row gutter={[8, 8]}>
                          <Col span={4}>
                            <Controller
                              name='is_qsr'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Checkbox
                                  {...props}
                                  checked={checkedValueIsqsr}
                                  onChange={(e) =>
                                    onChangeIsqsr(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </Col>

                          <Col span={20}>
                            <Typography className='formHeading'>
                              Is Qsr
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xl={8}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{ visibility: 'hidden' }}
                      ></Col>
                      <Col
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{ visibility: 'hidden' }}
                      ></Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[22, 22]} className='button' justify='center'>
                <Col span={24}></Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  {editId ? (
                    <Button
                      // className="filterButton"
                      className='detailsButton'
                      onClick={handleeditCancel}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      // className="filterButton"
                      className='detailsButton'
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  )}
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Button className='editDesignationButton' htmlType='submit'>
                    {editId ? 'Update' : 'Create'}
                  </Button>
                </Col>
                <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
              </Row>
            </>
          </Spin>
        </form>
      </Card>
    </Spin>
  );
};

export default RestarantModalForm;
