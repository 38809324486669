import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Typography,
  message,
  Spin,
  Breadcrumb,
  Input,
  Tooltip,
  Tabs,
  Upload,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { getUserThemes, updateUserTheme } from '../../Actions/UserThemesAction';
import { UserThemePreviewComponent } from '../../Utils/UserThemePreviewComponent';
import deleteIcon from '../../assests/deleteIcon.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import editIcon from '../../assests/editIcon.svg';
import {
  getRestaurantById,
  updateBrandingLogo,
} from '../../Actions/RestaurantAction';
import { deleteImage } from '../../Actions/ImageAction';
import { IoMdImage } from 'react-icons/io';

const Branding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    restaurant,
    allUserThemes,
    isUserThemesloading,
    isUpdateBrandingLogoLoading,
    isImageLoading,
  } = useSelector((state) => {
    const { allUserThemes, isUserThemesloading } = state.userThemes;
    const { restaurant, setRestaurantId, isUpdateBrandingLogoLoading } =
      state.restaurant;
    const { isImageLoading } = state.image;
    return {
      restaurant,
      allUserThemes,
      setRestaurantId,
      isUserThemesloading,
      isUpdateBrandingLogoLoading,
      isImageLoading,
    };
  });

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    watch,
    reset,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      online_org_logo: null,
      pdf_logo: null,
      instant_app_clip_logo: null,
    },
    // resolver: yupResolver(userThemeSchema),
  });
  const watching = watch();
  useEffect(() => {
    dispatch(getUserThemes(`api/user_view_themes`, allUserThemeSuccess));
  }, []);

  const allUserThemeSuccess = (res) => {
    setValue(
      'primary_color',
      res?.primary_color ? res?.primary_color : '#4B4283'
    );
    setValue(
      'secondary_color',
      res?.secondary_color ? res?.secondary_color : '#ED723Df'
    );
  };

  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const [tabKey, setTabKey] = useState('1');
  const [tabPreviews, setTabPreviews] = useState({
    1: null,
    2: null,
    3: null,
    4: null,
  });
  const [isFile, setIsFile] = useState('');
  const primaryColor = watch('primary_color');
  const secondaryColor = watch('secondary_color');

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };

  const handleUpdateBrandingLogo = (data) => {
    data.id = restaurant?.id;
    if (tabKey === '1') {
      data.online_org_logo = isFile;
    } else if (tabKey === '2') {
      data.app_clip_logo = isFile;
    } else if (tabKey === '3') {
      data.instant_app_clip_logo = isFile;
    } else if (tabKey === '4') {
      data.pdf_logo = isFile;
    }
    dispatch(updateBrandingLogo(data, successUpdateCallback));
  };

  const successUpdateCallback = () => {
    const successMessage =
      tabKey === '1'
        ? 'Online Store logo Updated Successfully'
        : tabKey === '2'
        ? 'App Clips IOS Logo Updated Successfully'
        : tabKey === '3'
        ? 'Instant App Android Logo Updated Successfully'
        : tabKey === '4'
        ? 'PDF Document Logo Updated Successfully'
        : 'Update Successful'; // Default message

    setTimeout(() => {
      message.success(successMessage);
    }, 1000);
    setTabPreviews((prev) => ({ ...prev, [tabKey]: null }));
  };

  const failureUpdateCallback = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse
          ? failureResponse?.error || failureResponse?.message
          : 'Something went wrong.'
      );
    }, 1000);
  };

  const onResetToDefault = (type) => {
    setIsFieldChanged(true);
    if (type === 'primary') {
      setValue('primary_color', '#4B4283');
    } else {
      setValue('secondary_color', '#ED723D');
    }
  };

  const handleCancel = () => {
    setTabPreviews((prev) => ({ ...prev, [tabKey]: null }));
  };

  const onChangeTab = (key) => {
    setTabKey(key);
  };

  const handleFileChange = (e) => {
    setIsFieldChanged(true);
    const file = e.target.files[0];
    setIsFile(file);
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setTabPreviews((prev) => ({
        ...prev,
        [tabKey]: previewUrl,
      }));
    }
    e.target.value = '';
  };

  const renderImageOrText = () => {
    if (tabKey === '1') {
      return tabPreviews['1'] ? (
        <img
          src={tabPreviews['1']}
          alt='Preview'
          style={{ width: '100px', height: '100px' }}
        />
      ) : restaurant?.online_org_logo_url ? (
        <img
          src={restaurant.online_org_logo_url}
          alt='Organization Logo'
          style={{ width: '100px', height: '100px' }}
        />
      ) : (
        <Col
          style={{
            width: '100px',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #7b7b7b',
            backgroundColor: '#f0f0f0',
          }}
        >
          <Typography style={{ fontSize: '28px' }}>
            {restaurant?.name ? restaurant.name[0] : 'P'}
          </Typography>
        </Col>
      );
    }

    if (tabKey === '2') {
      return tabPreviews['2'] ? (
        <img
          src={tabPreviews['2']}
          alt='Preview'
          style={{ width: '100px', height: '100px' }}
        />
      ) : restaurant?.app_clip_logo_url ? (
        <img
          src={restaurant.app_clip_logo_url}
          alt='App Clio hrader image'
          style={{ width: '100px', height: '100px' }}
        />
      ) : (
        ''
      );
    }

    if (tabKey === '3') {
      return tabPreviews['3'] ? (
        <img
          src={tabPreviews['3']}
          alt='Preview'
          style={{ width: '100px', height: '100px' }}
        />
      ) : restaurant?.instant_app_clip_logo_url ? (
        <img
          src={restaurant.instant_app_clip_logo_url}
          alt='Instant app logo '
          style={{ width: '100px', height: '100px' }}
        />
      ) : (
        <Col
          style={{
            width: '100px',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #7b7b7b',
            backgroundColor: '#f0f0f0',
          }}
        >
          <Typography style={{ fontSize: '28px' }}>
            {restaurant?.name ? restaurant.name[0] : 'P'}
          </Typography>
        </Col>
      );
    }

    if (tabKey === '4') {
      return tabPreviews['4'] ? (
        <img
          src={tabPreviews['4']}
          alt='Preview'
          style={{ width: '100px', height: '100px' }}
        />
      ) : restaurant?.pdf_logo_url ? (
        <img
          src={restaurant.pdf_logo_url}
          alt='Pdf Document logo'
          style={{ width: '100px', height: '100px' }}
        />
      ) : (
        <Col
          style={{
            width: '100px',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #7b7b7b',
            backgroundColor: '#f0f0f0',
          }}
        >
          <Typography style={{ fontSize: '28px' }}>
            {restaurant?.name ? restaurant.name[0] : 'P'}
          </Typography>
        </Col>
      );
    }
    return null;
  };

  const handleDeleteImage = (key) => {
    if (!tabPreviews[tabKey]) {
      dispatch(deleteImage(key, successCallbackImageDelete));
    } else {
      setTabPreviews((prev) => ({ ...prev, [tabKey]: null }));
    }
  };

  const successCallbackImageDelete = () => {
    setTimeout(() => {
      message.success('Your Image deleted Successfully');
    }, 1000);
    setTabPreviews((prev) => ({ ...prev, [tabKey]: null }));
    dispatch(getRestaurantById(restaurant?.id));
    // dispatch(getRestaurantDetails());
  };

  return (
    <Spin spinning={isUpdateBrandingLogoLoading || isImageLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row gutter={[24, 24]}>
            <Col xl={23} lg={23} md={23} sm={23} xs={23}>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurant()}
                  className='breadcrumRestaurant'
                >
                  Restaurants
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => navigate(-1)}
                  className='breadcrumRestaurant'
                >
                  {restaurant?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item className='breadcrumUsers'>
                  Branding
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={18}>
              <Typography className='heading'>Branding</Typography>
            </Col>
          </Row>
        </Col>
      </Row>
      <Col span={24}>
        <Tabs
          defaultActiveKey='1'
          className='branding-tab'
          onChange={onChangeTab}
          items={[
            {
              label: `Online Store logo`,
              key: '1',
            },
            {
              label: `App Clips IOS`,
              key: '2',
            },
            {
              label: `Instant App Android`,
              key: '3',
            },
            {
              label: `PDF Document logo`,
              key: '4',
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <Row>
          <Col span={16}>
            <form onSubmit={handleSubmit(handleUpdateBrandingLogo)}>
              <Row gutter={[16, 16]} className='button'>
                <Col span={18}>
                  <Row>
                    <Col span={18}>
                      <Typography className='formHeading'>
                        {tabKey === '1'
                          ? 'Online Store logo'
                          : tabKey === '2'
                          ? 'App clip header image'
                          : tabKey === '3'
                          ? 'Instant app logo'
                          : tabKey === '4'
                          ? 'PDF Document Logo'
                          : null}
                      </Typography>
                    </Col>
                  </Row>
                  {tabKey === '1' ? (
                    <Row style={{ position: 'relative' }}>
                      <Col className='image-preview'>{renderImageOrText()}</Col>
                      <Col>
                        <Controller
                          name='online_org_logo'
                          control={control}
                          render={() => {
                            return (
                              <>
                                <input
                                  type='file'
                                  accept='.png, .jpeg, .jpg'
                                  onChange={(e) => handleFileChange(e)}
                                  style={{ display: 'none' }}
                                  id='upload-input'
                                />
                                <label
                                  htmlFor='upload-input'
                                  className='upload-label'
                                >
                                  <img
                                    src={editIcon}
                                    width='35px'
                                    height='35px'
                                    alt='Edit Icon'
                                  />
                                </label>
                              </>
                            );
                          }}
                        />
                      </Col>
                      <Col>
                        <Typography
                          className='onlineStoreImageRemove'
                          onClick={() =>
                            handleDeleteImage(restaurant?.online_org_logo)
                          }
                        >
                          {restaurant?.online_org_logo || tabPreviews[tabKey]
                            ? 'remove'
                            : null}
                        </Typography>
                      </Col>
                    </Row>
                  ) : tabKey === '2' ? (
                    <Row>
                      <Col span={19}>
                        <Controller
                          name='app_clip_logo'
                          control={control}
                          render={() => {
                            return (
                              <>
                                <input
                                  type='file'
                                  accept='.png, .jpeg, .jpg'
                                  onChange={(e) => handleFileChange(e)}
                                  style={{ display: 'none' }}
                                  id='upload-input'
                                />
                                <label
                                  htmlFor='upload-input'
                                  // className='upload-label'
                                >
                                  <Row>
                                    <Col span={24} className='addImage'>
                                      <IoMdImage
                                        size='3.2em'
                                        color='#7B7B7B'
                                        title='Add Image'
                                      />
                                      <Typography
                                        style={{
                                          color: '#7b7b7b',
                                          fontSize: '16px',
                                        }}
                                      >
                                        {restaurant?.app_clip_logo
                                          ? 'Update Image'
                                          : 'Add Image'}
                                      </Typography>
                                      <Typography
                                        style={{
                                          color: '#7b7b7b',
                                          fontSize: '14px',
                                        }}
                                      >
                                        Use a 1800x1200 pixels PNG or JPEG image
                                        without transparency.
                                      </Typography>
                                    </Col>
                                  </Row>
                                </label>
                              </>
                            );
                          }}
                        />
                      </Col>
                      <Col
                        style={{
                          marginLeft: '5px',
                          marginTop: '10px',
                          width: '100px',
                          height: '100px',
                          position: 'relative',
                        }}
                      >
                        {renderImageOrText()}
                        <div
                          className='appClipDeleteLogo'
                          onClick={() =>
                            handleDeleteImage(restaurant?.app_clip_logo)
                          }
                        >
                          {restaurant?.app_clip_logo || tabPreviews[tabKey] ? (
                            <img src={deleteIcon} />
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  ) : tabKey === '3' ? (
                    <Row style={{ position: 'relative' }}>
                      <Col className='image-preview'>{renderImageOrText()}</Col>
                      <Col>
                        <Controller
                          name='instant_app_clip_logo'
                          control={control}
                          render={() => {
                            return (
                              <>
                                <input
                                  type='file'
                                  accept='.png, .jpeg, .jpg'
                                  onChange={(e) => handleFileChange(e)}
                                  style={{ display: 'none' }}
                                  id='upload-input'
                                />
                                <label
                                  htmlFor='upload-input'
                                  className='upload-label'
                                >
                                  <img
                                    src={editIcon}
                                    width='35px'
                                    height='35px'
                                    alt='Edit Icon'
                                  />
                                </label>
                              </>
                            );
                          }}
                        />
                      </Col>
                      <Col>
                        <Typography
                          className='onlineStoreImageRemove'
                          onClick={() =>
                            handleDeleteImage(restaurant?.instant_app_clip_logo)
                          }
                        >
                          {restaurant?.instant_app_clip_logo ||
                          tabPreviews[tabKey]
                            ? 'remove'
                            : null}
                        </Typography>
                      </Col>
                    </Row>
                  ) : tabKey === '4' ? (
                    <Row style={{ position: 'relative' }}>
                      <Col className='image-preview'>{renderImageOrText()}</Col>
                      <Col>
                        <Controller
                          name='pdf_logo'
                          control={control}
                          render={() => {
                            return (
                              <>
                                <input
                                  type='file'
                                  accept='.png, .jpeg, .jpg'
                                  onChange={(e) => handleFileChange(e)}
                                  style={{ display: 'none' }}
                                  id='upload-input'
                                />
                                <label
                                  htmlFor='upload-input'
                                  className='upload-label'
                                >
                                  <img
                                    src={editIcon}
                                    width='35px'
                                    height='35px'
                                    alt='Edit Icon'
                                  />
                                </label>
                              </>
                            );
                          }}
                        />
                      </Col>
                      <Col>
                        <Typography
                          className='onlineStoreImageRemove'
                          onClick={() =>
                            handleDeleteImage(restaurant?.pdf_logo)
                          }
                        >
                          {restaurant?.pdf_logo || tabPreviews[tabKey]
                            ? 'remove'
                            : null}
                        </Typography>
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              </Row>
              <Col span={18}>
                <Row
                  gutter={[16, 16]}
                  className='button'
                  align='middle'
                  justify='center'
                >
                  <Col span={24}></Col>
                  <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                    <Button
                      className={
                        isFieldChanged ? 'detailsButton' : 'classButton'
                      }
                      disabled={!isFieldChanged}
                      onClick={handleCancel}
                      size='large'
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                    <Button
                      size='large'
                      className={
                        isFieldChanged ? 'detailsButton' : 'classButton'
                      }
                      disabled={!isFieldChanged}
                      htmlType='submit'
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Col>
            </form>
          </Col>
          <Col span={8}>
            <UserThemePreviewComponent
              primaryColor={allUserThemes?.primary_color}
              secondaryColor={allUserThemes?.secondary_color}
              tabKey={tabKey}
            />
          </Col>
        </Row>
      </Col>
    </Spin>
  );
};

export default Branding;
