import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Breadcrumb,
  Pagination,
  Drawer,
  Tabs,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import '../Restaurant/Restaurant.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  FaCaretDown,
  FaCircle,
  FaIndianRupeeSign,
  FaRegCircleUser,
} from 'react-icons/fa6';
import foodRestaurant from '../../assests/foodRestaurant.svg';
import takeAway from '../../assests/takeAway.svg';
import Search from 'antd/lib/input/Search';
import CustomTable from '../CustomTable/CustomTable';
import { DateRangePicker } from 'react-date-range';
import { IoMdCalendar } from 'react-icons/io';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { startOfYear, endOfYear } from 'date-fns';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getCustomerOrders } from '../../Actions/CustomerAction';

dayjs.extend(customParseFormat);

const CustomerDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { Customer, allCustomerOrders, restaurant, isCustomerLoading } =
    useSelector((state) => {
      const { restaurant } = state.restaurant;
      const { Customer, allCustomerOrders, isCustomerLoading } = state.customer;
      return {
        Customer,
        allCustomerOrders,
        restaurant,
        isCustomerLoading,
      };
    });
  const location = useLocation();
  const customerId = location.state?.customerId;
  const rangeRef = useRef(null);
  const pickerRef = useRef(null);
  const [pickerPosition, setPickerPosition] = useState({ top: 0, left: 0 });
  const [isViewAddressesOpen, setIsViewAddressesOpen] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [isTabChanged, setIsTabChanged] = useState('orders');
  const [selectionRange, setSelectionRange] = useState({
    startDate: startOfYear(new Date()),
    endDate: endOfYear(new Date()),
    key: 'selection',
  });
  const [selectedButton, setSelectedButton] = useState('set1');
  const [orderPage, setOrderPage] = useState(1);

  const { orgId } = useParams();
  const { length } = allCustomerOrders?.customer_orders || {};

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr?.split('-').map(Number);
    return new Date(year, month - 1, day);
  };

  useEffect(() => {
    if (customerId) {
      dispatch(
        getCustomerOrders(
          `api/customer_orders?page=${orderPage}&customer_id=${customerId}`
        )
      );
    }
  }, [orderPage, customerId]);

  useEffect(() => {
    if (allCustomerOrders?.customer_orders?.length > 0) {
      const filtered = allCustomerOrders?.customer_orders?.filter((item) => {
        const itemDate = parseDate(item?.order_created_date_time?.created_date);
        return (
          itemDate >= selectionRange.startDate &&
          itemDate <= selectionRange.endDate
        );
      });
      setAllOrders(filtered);
    }
  }, [allCustomerOrders, selectionRange]);

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setSelectionRange({ startDate, endDate, key: 'selection' });
    setIsPickerOpen(true);
    filterData(startDate, endDate);
  };

  const filterData = (startDate, endDate) => {
    const filtered = allCustomerOrders?.customer_orders?.filter((item) => {
      const itemDate = parseDate(item.order_created_date_time.created_date);
      return itemDate >= startDate && itemDate <= endDate;
    });
    setAllOrders(() => filtered);
  };

  const togglePicker = () => {
    if (!isPickerOpen) {
      const rect = rangeRef.current.getBoundingClientRect();
      setPickerPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
    setIsPickerOpen(!isPickerOpen);
  };

  const handleClickOutside = (event) => {
    if (
      rangeRef.current &&
      !rangeRef.current.contains(event.target) &&
      pickerRef.current &&
      !pickerRef.current.contains(event.target)
    ) {
      setIsPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseOrganization = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCloseCustomers = () => {
    setAllOrders('');
    navigate(`/restaurants/${orgId}/customers`);
  };
  const Columns = [
    {
      title: 'Order ID',
      dataIndex: 'order_number',
      width: '9%',
      sorter: (a, b) => {
        const numA = parseInt(a.order_number.replace(/\D/g, ''), 10);
        const numB = parseInt(b.order_number.replace(/\D/g, ''), 10);
        return numA - numB;
      },
      render: (Id, { id, order_number }) => {
        return (
          <Row>
            <Col>
              <Typography>{order_number}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Items',
      dataIndex: 'items',
      width: '12%',
      render: (items) => {
        return (
          <Row>
            <Col>
              <Typography style={{ wordSpacing: '-5px' }}>
                {items.join(', ')}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10%',
      render: (status, { status_color }) => {
        return (
          <Row>
            <Col>
              <Typography
                className='odrersStatus'
                style={{ color: status_color }}
              >
                <FaCircle className='circleIcon' />
                {status}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Date Created',
      dataIndex: 'order_created_date_time',
      width: '10%',
      sorter: (a, b) => {
        const formatDateTime = (created_date, created_time) => {
          const cleanedTime = created_time.replace(' IST', '');
          return dayjs(`${created_date} ${cleanedTime}`, 'DD-MM-YYYY hh:mm A');
        };
        const dateA = formatDateTime(
          a.order_created_date_time.created_date,
          a.order_created_date_time.created_time
        );
        const dateB = formatDateTime(
          b.order_created_date_time.created_date,
          b.order_created_date_time.created_time
        );
        return dateA - dateB;
      },
      render: (order_created_date_time) => {
        return (
          <Row>
            <Col>
              <Typography>{order_created_date_time.created_time}</Typography>
              <Typography>{order_created_date_time.created_date}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Order Type',
      dataIndex: 'order_type',
      width: '8%',
      render: (order_type) => {
        return (
          <Row>
            <Col>
              <Typography>{order_type}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Payment Modes',
      dataIndex: 'payment_modes',
      width: '7%',
      render: (payment_modes) => {
        return (
          <Row>
            <Col>
              <Typography style={{ wordSpacing: '-5px' }}>
                {payment_modes.join(', ')}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Bill Amount',
      dataIndex: 'bill_amount',
      width: '6%',
      sorter: (a, b) => a.bill_amount - b.bill_amount,
      render: (bill_amount) => {
        return (
          <Row>
            <Col>
              <Typography>
                <FaIndianRupeeSign fontSize='10px' />
                {bill_amount}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
  ];

  const handleOpenAddressDrawer = () => {
    setIsViewAddressesOpen(true);
  };

  const handleCloseAddressDrawer = () => {
    setIsViewAddressesOpen(false);
  };

  const onChangeTab = (value) => {
    setIsTabChanged(value);
  };

  return (
    <Spin spinning={isCustomerLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurant()}
              className='breadcrumRestaurant'
            >
              Restaurants
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseOrganization()}
              className='breadcrumRestaurant'
            >
              {restaurant?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseCustomers()}
              className='breadcrumRestaurant'
            >
              Customers
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {Customer?.phone_with_country_code} | {Customer?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={18}>
          <Typography className='heading'>
            {Customer?.phone_with_country_code} | {Customer?.name}
          </Typography>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className='customers'>
        <Col span={4} className='customerDetails'>
          <Typography className='customerData'>Total Spend</Typography>
          <Typography className='subHeading'>
            ₹{Customer?.total_spend}
          </Typography>
        </Col>
        <Col span={4} className='customerDetails'>
          <Typography className='customerData'>Total Orders</Typography>
          <Typography className='subHeading'>
            {Customer?.total_orders}
          </Typography>
        </Col>
        <Col span={4} className='customerDetails'>
          <Typography className='customerData'>Average Order Value</Typography>
          <Typography className='subHeading'>
            ₹{Customer?.average_order_value}
          </Typography>
        </Col>
        <Col span={4} className='customerDetails'>
          <Typography className='customerData'>Surveys Completed</Typography>
          <Typography className='subHeading'>
            {Customer?.surveys_complated}
          </Typography>
        </Col>
        <Col span={4}>
          <Typography className='customerData'>Average Order Type</Typography>
          <Typography className='subHeading'>
            {Customer?.average_order_type}
          </Typography>
        </Col>
        <Col span={1}>
          <Typography style={{ marginTop: '10px' }}>
            <img src={foodRestaurant} />{' '}
          </Typography>
          <Typography style={{ marginTop: '8px' }}>
            <img src={takeAway} />
          </Typography>
        </Col>
        <Col span={4} style={{ marginTop: '20px' }}>
          <Typography className='customerData'>Customer Retention</Typography>
          <Typography className='subHeading'>
            {Customer?.customer_retention?.yearly}%
          </Typography>
        </Col>
      </Row>
      <Row gutter={[12, 12]} style={{ marginTop: '15px' }}>
        <Col span={24}>
          <Typography className='customerInfoHeading'>
            Customer Information
          </Typography>
        </Col>
        <Col span={6}>
          <Row className='customerInformation'>
            <Col span={24}>
              <FaRegCircleUser
                style={{ fontSize: '30px', marginBottom: '10px' }}
              />
              <Typography className='customerHeading'>Name</Typography>
              <Typography className='customerData'>{Customer?.name}</Typography>
            </Col>
            <Col span={24}>
              <Typography className='customerHeading'>Email </Typography>
              <Typography className='customerData'>
                {Customer?.email ? Customer?.email : '-'}
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className='customerHeading'>Phone</Typography>
              <Typography className='customerData'>
                {Customer?.phone_with_country_code}
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className='customerHeading'>First Visited</Typography>
              <Typography className='customerData'>
                {Customer?.first_visited}
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className='customerHeading'>Relation</Typography>
              <Typography className='customerData'>
                {Customer?.relation}
              </Typography>
            </Col>
            <Col span={15}>
              <Button className='orderButton' onClick={handleOpenAddressDrawer}>
                View addresses
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={18} style={{ textAlign: 'left' }}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Tabs
                activeKey={isTabChanged}
                className='customization-tab'
                type='card'
                onChange={onChangeTab}
                items={[
                  {
                    label: `Orders`,
                    key: 'orders',
                  },
                  {
                    label: `Surveys`,
                    key: 'surveys',
                  },
                  {
                    label: `Queue activity`,
                    key: 'queueActivity',
                  },
                ]}
              />
            </Col>
            {/* <Col span={12}>
              <Search placeholder='Search order' />
            </Col> */}

            <Col
              span={24}
              style={{ position: 'relative', marginBottom: '7px' }}
            >
              <Typography
                ref={rangeRef}
                onClick={togglePicker}
                style={{
                  position: 'relative',
                  cursor: 'pointer',
                  display: 'inline-block',
                  border: '1px solid #7b7b7b',
                  borderRadius: '4px',
                  padding: '7px 33px',
                  fontFamily: 'circular-400',
                  wordSpacing: '-5px',
                }}
              >
                <IoMdCalendar
                  style={{
                    position: 'absolute',
                    left: '6px',
                    fontSize: '20px',
                  }}
                />
                {`${formatDate(selectionRange.startDate)} - ${formatDate(
                  selectionRange.endDate
                )}`}
                <FaCaretDown
                  style={{
                    position: 'absolute',
                    marginLeft: '7px',
                    fontSize: '18px',
                  }}
                />
              </Typography>
              {isPickerOpen && (
                <div
                  ref={pickerRef}
                  style={{
                    position: 'absolute',
                    top: '100%',
                    left: '0',
                    width: '100%',
                    zIndex: 100,
                  }}
                >
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    rangeColors={['#3a3a3a']}
                  />
                </div>
              )}
            </Col>
          </Row>
          {isTabChanged === 'orders' ? (
            <Row className='ordersTable'>
              <Col span={24}>
                <CustomTable columns={Columns} data={allOrders} />
                {allCustomerOrders.customer_orders && length > 0 ? (
                  <Col span={24}>
                    <Row justify='center' style={{ margin: 30 }}>
                      <Pagination
                        total={allCustomerOrders?.length}
                        onChange={(e) => setOrderPage(e)}
                        responsive={true}
                        size='large'
                        current={orderPage}
                        showSizeChanger={false}
                      />
                    </Row>
                  </Col>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          ) : (
            'No data'
          )}

          {isViewAddressesOpen && (
            <Drawer
              className='customerAddressHeader'
              closable={false}
              title={
                <Row>
                  <Col span={22}>
                    <Typography className='drawerHeading'>
                      Saved addresses
                    </Typography>
                  </Col>
                  <Col span={2}>
                    <CloseOutlined
                      onClick={handleCloseAddressDrawer}
                      style={{ cursor: 'pointer' }}
                    />
                  </Col>
                </Row>
              }
              placement='right'
              open={isViewAddressesOpen}
              onClose={handleCloseAddressDrawer}
            >
              <Row>
                {Customer?.user_addresses?.map((address, i) => {
                  const fullAddress = [
                    address?.address_line_1,
                    address?.address_line_2,
                    address?.landmark,
                    address?.other_location_detail,
                    address?.city,
                    address?.zip_code,
                  ]
                    .filter(Boolean)
                    .join(', ');
                  return (
                    <Col span={24} key={i}>
                      <Row gutter={[4, 4]}>
                        <Col span={24}>
                          <Typography className='customerAddressType'>
                            {address.address_type}
                          </Typography>
                        </Col>
                        <Col span={24}>
                          <Typography className='customerFullAddress'>
                            {fullAddress}
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            </Drawer>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default CustomerDetails;
