import React, { useState } from 'react';
import { Table } from 'antd';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { useDispatch } from 'react-redux';
import { getCategory, reOrderCategory } from '../../Actions/CategoryAction';
import {
  getOrderProcess,
  reOrderOrderProcess,
} from '../../Actions/OrderProcessAction';

import { getCfdImages, reOrderCfdImage } from '../../Actions/CfdImagesAction';
import {
  getCategoryItem,
  reOrderCategoryItem,
} from '../../Actions/CategoryItemAction';
import {
  getSurveyQuestion,
  reOrderSurveyQuestion,
} from '../../Actions/surveyQuestionAction';
import {
  getCustomizations,
  reOrderCustomization,
} from '../../Actions/CustomizationAction';
import {
  getCustomizationItem,
  reOrderCustomizationItem,
} from '../../Actions/CustomizationItemAction';

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const CustomTable = (props) => {
  const {
    columns,
    data,
    currentPage,
    type,
    setCategoryId,
    setSurveyId,
    CategoryItem,
    customizationId,
    // scroll
  } = props;
  const dispatch = useDispatch();

  const getRowClassName = (record) => {
    return record?.published === false ? 'unpublished-row' : '';
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const findOldObj = data[oldIndex];
      const newDataObj = { id: findOldObj?.id, position: newIndex + 1 };
      if (type === 'orderprocess') {
        dispatch(
          reOrderOrderProcess(newDataObj, successCallbackReorderProcess)
        );
      } else if (type === 'cfdimages') {
        dispatch(reOrderCfdImage(newDataObj, successCallbackCfdImage));
      } else if (type === 'category') {
        dispatch(reOrderCategory(newDataObj, successCallbackCategory));
      } else if (type === 'categoryitems') {
        dispatch(reOrderCategoryItem(newDataObj, successCallbackCategoryItem));
      } else if (type === 'customization') {
        dispatch(
          reOrderCustomization(newDataObj, successCallbackCustomization)
        );
      } else if (type === 'customizationitems') {
        dispatch(
          reOrderCustomizationItem(newDataObj, successCallbackCustomizationItem)
        );
      } else {
        newDataObj.survey_id = setSurveyId;
        dispatch(
          reOrderSurveyQuestion(newDataObj, successCallbackSurveyQuestion)
        );
      }
    }
  };

  const successCallbackCfdImage = () => {
    dispatch(getCfdImages(`api/organization_carousel_screens`));
  };
  const successCallbackSurveyQuestion = () => {
    dispatch(getSurveyQuestion(`api/questions?survey_id=${setSurveyId}`));
  };
  const successCallbackCategoryItem = () => {
    dispatch(
      getCategoryItem(
        `api/category_items?page=${currentPage}&category_id=${setCategoryId}`
      )
    );
  };
  const successCallbackCategory = () => {
    dispatch(getCategory(`api/categories?page=${currentPage}`));
  };
  const successCallbackReorderProcess = () => {
    dispatch(
      getOrderProcess(`api/organization_order_processes?is_sequence=true`)
    );
  };
  const successCallbackCustomization = () => {
    dispatch(
      getCustomizations(`api/customizations?item_id=${CategoryItem?.item?.id}`)
    );
  };
  const successCallbackCustomizationItem = () => {
    dispatch(
      getCustomizationItem(
        `api/customization_items?page=${currentPage}&customization_id=${customizationId}`
      )
    );
  };
  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = data
      ? data?.findIndex((x) => x?.position === restProps['data-row-key'])
      : -1;
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
      rowKey='position'
      rowClassName={getRowClassName}
      scroll={{ x: 400 }}
      // sticky
      size='small'
    />
  );
};
export default CustomTable;
