import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Pagination,
  Typography,
  Spin,
  Tooltip,
  Breadcrumb,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import { useNavigate, useParams } from 'react-router-dom';
import Search from 'antd/lib/transfer/search';
import {
  getCustomerById,
  getCustomerOrders,
  getCustomers,
} from '../../Actions/CustomerAction';
import CustomerDetails from './CustomerDetails';
import { FaHashtag, FaIndianRupeeSign } from 'react-icons/fa6';

const Customers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const { restaurant, setRestaurantId, isCustomerLoading, allCustomers } =
    useSelector((state) => {
      const { restaurant, setRestaurantId } = state.restaurant;
      const { allCustomers, isCustomerLoading } = state.customer;
      return {
        restaurant,
        isCustomerLoading,
        setRestaurantId,
        allCustomers,
      };
    });

  const { length } = allCustomers?.customers || {};
  const { orgId } = useParams();

  useEffect(() => {
    dispatch(getCustomers(`api/organization_customers?page=${currentPage}`));
  }, [currentPage]);

  const handleOpen = (Id) => {
    dispatch(getCustomerById(Id));
    localStorage.setItem('organization_id', orgId);
    navigate(`/restaurants/${orgId}/customers/${Id}/customerdetails`, {
      state: { customerId: Id },
    });
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };

  const handleCloseOrganization = () => {
    navigate(`/restaurants/${orgId}`);
  };

  const Columns = [
    {
      title: 'Number/Name',
      dataIndex: 'name',
      width: '10%',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (Id, { id, name, phone_with_country_code }) => {
        return (
          <Row>
            <Col style={{ cursor: 'pointer' }}>
              <Tooltip
                title={`View ${name} Details`}
                onClick={() => handleOpen(id)}
              >
                <Typography>{phone_with_country_code}</Typography>
                <Typography>{name}</Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Total orders',
      dataIndex: 'total_orders',
      width: '5%',
      sorter: (a, b) => a.total_orders - b.total_orders,
      render: (total_orders) => {
        return (
          <Row>
            <Col>
              <Typography>
                <FaHashtag fontSize='10px' />
                {total_orders}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Total spend',
      dataIndex: 'total_spend',
      width: '5%',
      sorter: (a, b) => a.total_spend - b.total_spend,
      render: (total_spend) => {
        return (
          <Row>
            <Col>
              <Typography>₹{total_spend}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Retention',
      dataIndex: 'customer_retention',
      width: '5%',
      sorter: (a, b) =>
        a.customer_retention.monthly - b.customer_retention.monthly,
      render: (customer_retention) => {
        return (
          <Row>
            <Col>
              <Typography>{customer_retention.monthly}%</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Average order type',
      dataIndex: 'average_order_type',
      width: '6%',
      sorter: (a, b) =>
        a.average_order_type.localeCompare(b.average_order_type),
      render: (average_order_type) => {
        return (
          <Row>
            <Col>
              <Typography>{average_order_type}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Average order value',
      dataIndex: 'average_order_value',
      width: '5%',
      sorter: (a, b) => a.average_order_value - b.average_order_value,
      render: (average_order_value) => {
        return (
          <Row>
            <Col>
              <Typography>
                <FaIndianRupeeSign fontSize='10px' />
                {average_order_value}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
  ];

  const showLoading = isCustomerLoading;
  return (
    <Spin spinning={showLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row gutter={[24, 24]}>
            <Col xl={23} lg={23} md={23} sm={23} xs={23}>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurant()}
                  className='breadcrumRestaurant'
                >
                  Restaurants
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => handleCloseOrganization()}
                  className='breadcrumRestaurant'
                >
                  {restaurant?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item className='breadcrumUsers'>
                  Customers
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={14}>
              <Typography className='heading'>Customers</Typography>
            </Col>

            <Col span={10}>
              <Search placeholder='Search customer name' />
            </Col>
            <Col span={24}>
              <CustomTable
                columns={Columns}
                selectedRow={selectedRow}
                currentPage={currentPage}
                setSelectedRow={setSelectedRow}
                data={allCustomers?.customers}
              />
              {allCustomers?.customers && length > 0 ? (
                <Col span={24}>
                  <Row justify='center' style={{ margin: 30 }}>
                    <Pagination
                      total={allCustomers?.length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default Customers;
