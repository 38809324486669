import constants from '../Utils/constants';
const initialState = {
  isCustomerLoading: false,
  allCustomers: [],
  allCustomerOrders: [],
  Customer: {},
};

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CUSTOMERS_REQUEST:
      return {
        ...state,
        isCustomerLoading: true,
      };
    case constants.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isCustomerLoading: false,
        allCustomers: action.payload,
      };
    case constants.GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        isCustomerLoading: false,
      };
    case constants.GET_CUSTOMER_BY_ID_REQUEST:
      return {
        ...state,
        isCustomerLoading: true,
      };
    case constants.GET_CUSTOMER_BY_ID_SUCCESS:
      return {
        ...state,
        isCustomerLoading: false,
        Customer: action?.payload,
      };
    case constants.GET_CUSTOMER_BY_ID_FAILURE:
      return {
        ...state,
        isCustomerLoading: false,
      };
    case constants.GET_CUSTOMER_ORDERS_REQUEST:
      return {
        ...state,
        isCustomerLoading: true,
      };
    case constants.GET_CUSTOMER_ORDERS_SUCCESS:
      return {
        ...state,
        isCustomerLoading: false,
        allCustomerOrders: action.payload,
      };
    case constants.GET_CUSTOMER_ORDERS_FAILURE:
      return {
        ...state,
        isCustomerLoading: false,
      };
    default:
      return state;
  }
};
