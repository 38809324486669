import API from '../Utils/API';

export const getCustomers = (url) => (dispatch) => {
  dispatch({ type: 'GET_CUSTOMERS_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_CUSTOMERS_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_CUSTOMERS_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};

export const getCustomerById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_CUSTOMER_BY_ID_REQUEST' });
  API.get(`api/organization_customer/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_CUSTOMER_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_CUSTOMER_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};

export const getCustomerOrders = (url) => (dispatch) => {
  dispatch({ type: 'GET_CUSTOMER_ORDERS_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({
        type: 'GET_CUSTOMER_ORDERS_SUCCESS',
        payload: response?.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_CUSTOMER_ORDERS_FAILURE',
        payload: error?.response?.data,
      });
    });
};
