import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Drawer,
  Typography,
  Input,
  DatePicker,
  Upload,
  Rate,
  Checkbox,
  Radio,
  Select,
  Divider,
} from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import _ from 'lodash';
import { IoMdImage } from 'react-icons/io';
import TextArea from 'antd/lib/input/TextArea';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

const SurveyResponseDetails = () => {
  const [width] = useState(window.innerWidth);
  const navigate = useNavigate();

  const location = useLocation();
  const readOnlyStyle = { pointerEvents: 'none' };

  const { orgId, surveyId, responseId } = useParams();

  const { allSurveyResponse, allSurveyAttemptResult } = useSelector((state) => {
    const { allSurveyResponse, allSurveyAttemptResult } = state.survey;
    return {
      allSurveyResponse,
      allSurveyAttemptResult,
    };
  });

  console.log({ allSurveyAttemptResult });

  const [value, setValue] = useState('');

  const the_survey = _.filter(allSurveyResponse?.attempts, function (o) {
    return o?.survey_response?.attempt_id === responseId;
  });
  console.log({ allSurveyResponse, the_survey });

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleCloseDrawer = () => {
    navigate(`/restaurants/${orgId}/surveys/${surveyId}/responses`);
  };

  //   const optionsArray = the_survey_question[0]?.answer_options
  //     .split('\r\n')
  //     .filter(Boolean);

  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      closable={false}
      title={
        <Row>
          <Col span={2}>
            <img
              src={Back}
              alt='props'
              onClick={handleCloseDrawer}
              style={{ cursor: 'pointer' }}
            />
          </Col>
          <Col span={22}>
            <Typography className='drawerHeading'>
              View survey response
            </Typography>
          </Col>
        </Row>
      }
      placement='right'
      onClose={handleCloseDrawer}
      open={true}
      width={width > 400 ? '500px' : '100%'}
    >
      {
        <div>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailsubheading'>Order Number</Typography>
              <Typography className='detailsubheading'>
                {the_survey[0]?.survey_response?.order_no}
              </Typography>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailsubheading'>
                Customer details
              </Typography>
              <Typography className='detailsubheading'>
                {the_survey[0]?.survey_response?.customer_phone || ''}
                {the_survey[0]?.survey_response?.customer_phone &&
                the_survey[0]?.survey_response?.customer_name
                  ? ' | '
                  : ''}
                {the_survey[0]?.survey_response?.customer_name || ''}
              </Typography>
            </Col>
          </Row>
          <Divider style={{ borderColor: '#7b7b7b' }} />
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                {allSurveyAttemptResult?.map((result) =>
                  result?.question_type === 'Rapidfire::Questions::Checkbox' ? (
                    <Col span={24}>
                      <Typography className='detailsubheading'>
                        {result?.question_text}
                      </Typography>
                      {Object.entries(result.results).map(
                        ([option, checked], idx) => (
                          <Checkbox
                            key={idx}
                            checked={Boolean(checked)}
                            className='detailsubheading'
                          >
                            {option}
                          </Checkbox>
                        )
                      )}
                    </Col>
                  ) : result?.question_type === 'Rapidfire::Questions::Date' ? (
                    <Col span={24}>
                      <Typography className='detailsubheading'>
                        {result?.question_text}
                      </Typography>
                      <DatePicker
                        size='large'
                        value={dayjs(result?.results[0])} // Convert string to dayjs object
                        format='YYYY-MM-DD'
                        style={{ borderRadius: '5px', ...readOnlyStyle }}
                        className='detailsubheading'
                      />
                    </Col>
                  ) : result?.question_type === 'Rapidfire::Questions::Long' ? (
                    <Col span={24}>
                      <Typography className='detailsubheading'>
                        {result?.question_text}
                      </Typography>
                      <TextArea
                        size='large'
                        className='inputLabel'
                        value={result?.results?.toString()}
                        style={{
                          fontFamily: 'Circular-400',
                          wordSpacing: '-4px',
                          ...readOnlyStyle,
                        }}
                      />
                    </Col>
                  ) : result?.question_type ===
                    'Rapidfire::Questions::Numeric' ? (
                    <Col span={24}>
                      <Typography className='detailsubheading'>
                        {result?.question_text}
                      </Typography>
                      <Rate
                        className='customRate'
                        value={result?.results.toString()}
                        disabled
                      />
                    </Col>
                  ) : result?.question_type ===
                    'Rapidfire::Questions::Radio' ? (
                    <Col span={24}>
                      <Typography className='detailsubheading'>
                        {result?.question_text}
                      </Typography>
                      <Radio.Group
                        defaultValue={
                          Object.entries(result.results).find(
                            ([, value]) => value === 1
                          )?.[0] || null
                        }
                        style={readOnlyStyle}
                      >
                        {Object.entries(result.results).map(
                          ([option, selected], idx) => (
                            <Radio
                              key={idx}
                              value={option}
                              className='detailsubheading'
                            >
                              {option}
                            </Radio>
                          )
                        )}
                      </Radio.Group>
                    </Col>
                  ) : result?.question_type ===
                    'Rapidfire::Questions::Select' ? (
                    <Col span={12}>
                      <Typography className='detailsubheading'>
                        {result?.question_text}
                      </Typography>
                      <Select
                        defaultValue={
                          Object.entries(result.results).find(
                            ([, value]) => value === 1
                          )?.[0] || undefined
                        }
                        style={{ width: '100%', ...readOnlyStyle }}
                        className='detailsubheading'
                      >
                        {Object.entries(result.results).map(
                          ([option, selected], idx) => (
                            <Select.Option key={idx} value={option}>
                              {option}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </Col>
                  ) : result?.question_type ===
                    'Rapidfire::Questions::Short' ? (
                    <Col span={24}>
                      <Typography className='detailsubheading'>
                        {result?.question_text}
                      </Typography>
                      <Input
                        size='large'
                        className='inputLabel'
                        value={result?.results.toString()}
                        style={{
                          fontFamily: 'Circular-400',
                          wordSpacing: '-4px',
                          ...readOnlyStyle,
                        }}
                      />
                    </Col>
                  ) : result?.question_type ===
                    'Rapidfire::Questions::Information' ? (
                    <Col span={24}>
                      <Typography className='detailsubheading'>
                        {result?.question_text}
                      </Typography>
                    </Col>
                  ) : // : result?.question_type === 'Rapidfire::Questions::File' ? (
                  //   <Upload
                  //     className='fileUpload'
                  //     listType='picture-card'
                  //     accept='image/png,image/jpeg,image/jpg'
                  //     maxCount={1}
                  //     beforeUpload={() => false}
                  //   >
                  //     <Row justify='center'>
                  //       <Col span={24}>
                  //         {
                  //           <IoMdImage
                  //             size='3em'
                  //             color='#7B7B7B'
                  //             title='Add Image'
                  //           />
                  //         }
                  //       </Col>
                  //       <Col span={24}>
                  //         <Typography className='uploadTextLine'>
                  //           Click or drag image file to this area to upload
                  //         </Typography>
                  //         <Typography className='uploadTextLine2'>
                  //           Supports JPG,JPEG,PNG
                  //         </Typography>
                  //       </Col>
                  //     </Row>
                  //   </Upload>
                  // ) : result?.question_type ===
                  //   'Rapidfire::Questions::MultiFile' ? (
                  //   <Upload
                  //     className='fileUpload'
                  //     listType='picture-card'
                  //     accept='image/png,image/jpeg,image/jpg'
                  //     beforeUpload={() => false}
                  //   >
                  //     <Row justify='center'>
                  //       <Col span={24}>
                  //         {
                  //           <IoMdImage
                  //             size='3em'
                  //             color='#7B7B7B'
                  //             title='Add Image'
                  //           />
                  //         }
                  //       </Col>
                  //       <Col span={24}>
                  //         <Typography className='uploadTextLine'>
                  //           Click or drag image file to this area to upload
                  //         </Typography>
                  //         <Typography className='uploadTextLine2'>
                  //           Supports JPG,JPEG,PNG
                  //         </Typography>
                  //       </Col>
                  //     </Row>
                  //   </Upload>
                  // )
                  null
                )}
              </Row>
            </Col>
          </Row>
        </div>
      }
    </Drawer>
  );
};
export default SurveyResponseDetails;
