import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Typography,
  message,
  Spin,
  Tooltip,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  getCountry,
  deleteCountry,
  getCountryById,
} from '../../Actions/countryAction';
import CountryModalForm from './CountryModalForm';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
// import CountryDetails from "./CountryDetails";
import plus from '../../assests/plus.svg';
import filtericon from '../../assests/filtericon.svg';
import { useNavigate } from 'react-router-dom';

const Country = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [countryDetailId, setcountryDetailId] = useState('');
  const [countryTabId, setcountryTabId] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const dispatch = useDispatch();
  const { allCountry, isCountryloading } = useSelector((state) => {
    const { allCountry, isCountryloading } = state.country;
    return {
      allCountry,
      isCountryloading,
    };
  });
  const { length } = allCountry || {};
  const { countries } = allCountry;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCountry(`api/countries?page=${currentPage}`));
  }, [dispatch, currentPage]);

  const successDelete = () => {
    setTimeout(() => {
      message.success(`Country Delete Successfully`);
    }, 1000);
    dispatch(getCountry(`api/countries?page=${currentPage}`));
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleOpenRestaurant = (orgId) => {
    navigate('/countryDetails');
    setcountryTabId(true);
    setcountryDetailId(orgId);
    dispatch(getCountryById(orgId));
    dispatch({
      type: 'GET_SELECTED_COUNTRY_ID',
      payload: orgId,
    });
  };
  const handleSortButtonClick = () => {
    const sortedResults = [...countries].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (sortOrder === 'asc') {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });

    setSortedData(sortedResults);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  const Columns = [
    {
      title: 'Country Name',
      dataIndex: 'name',
      width: '15%',
      render: (Id, { id, name }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${name} Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  className='UserDetailIndustryTextData'
                  onClick={() => handleOpenRestaurant(id)}
                >
                  {_.startCase(name)}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Code',
      dataIndex: 'code',
      width: '8%',
      render: (code) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {code}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Currency Code',
      dataIndex: 'currency_code',
      width: '8%',
      render: (currency_code) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {currency_code}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Country Code',
      dataIndex: 'country_code',
      width: '8%',
      render: (country_code) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {country_code}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '4%',
      render: (id) => {
        return (
          <Row justify='space-between'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteCountry]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isCountryloading}>
      <Row gutter={[24, 24]} style={{ marginTop: '-25px' }}>
        {isModalVisible ? (
          <CountryModalForm
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            editId={editId}
            setEditId={setEditId}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          ></CountryModalForm>
        ) : (
          <>
            <Col xl={16} lg={16} md={10} sm={10} xs={10}>
              <Typography className='heading'>Countries</Typography>
            </Col>

            <Col xl={8} lg={8} md={14} sm={14} xs={14}>
              <Row justify='end' gutter={[8, 8]}>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Button
                    className='detailsButton'
                    onClick={(id) => setIsModalVisible(id)}
                    icon={<img src={plus} alt='props' />}
                  >
                    Create New
                  </Button>
                </Col>
                <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                  <Button
                    className='filterButton'
                    onClick={handleSortButtonClick}
                    icon={<img src={filtericon} alt='props' />}
                  >
                    Filter
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <CustomTable
                columns={Columns}
                data={sortOrder === 'asc' ? allCountry?.countries : sortedData}
              />
            </Col>
            {allCountry?.countries && length > 0 ? (
              <Col span={24}>
                <Row justify='center' className='pagination'>
                  <Pagination
                    total={allCountry?.length}
                    onChange={(e) => setCurrentPage(e)}
                    responsive={true}
                    size='large'
                    current={currentPage}
                    showSizeChanger={false}
                  />
                </Row>
              </Col>
            ) : (
              ''
            )}
          </>
        )}
        {/* )} */}
      </Row>
    </Spin>
  );
};

export default Country;
